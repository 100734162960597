module.exports = [{
      plugin: require('../.yarn/cache/gatsby-plugin-anchor-links-npm-1.2.1-7db63a8548-373724406b.zip/node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-8ca139db13/0/cache/gatsby-plugin-manifest-npm-5.12.0-7e9af7e841-6c8459995b.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/assets/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"132529bafb6f912f28bccd9836f6716a"},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-8a3898c595/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
